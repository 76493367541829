@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply min-w-0 min-h-0 shrink-0 scrollbar-thin;
}
*::selection {
  @apply bg-brand-500/80 text-white;
}

:root {
  @apply text-[14px] overscroll-none;
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}

html,
body {
  @apply stack size-full flex-auto overscroll-none antialiased min-h-screen font-sans bg-contrast-0 relative text-contrast-700;
}

/* TODO: standardize this */
.text-muted {
  @apply text-contrast-500;
}

@layer utilities {
  .link-underline {
    @apply underline decoration-2 underline-offset-4 decoration-contrast-900/5 hover:decoration-contrast-900/70 transition;
  }
}

hr {
  @apply border-contrast-100;
}

/* Chart colors */

@layer base {
  :root {
    --sidebar-background: 60 9% 96%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    /* --sidebar-border: 60 5% 96%; */
    --sidebar-border: 60 9% 98%;
    --sidebar-ring: 217.2 91.2% 59.8%;

    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;

    --chart-border: 20 6% 90%;
  }
  [data-registry='plate'].dark {
    --brand: 213.3 93.9% 67.8%;
    --highlight: 48 96% 53%;
  }
  [data-registry='plate'] {
    --radius: 0.5rem;
    --brand: 217.2 91.2% 59.8%;
    --highlight: 47.9 95.8% 53.1%;
  }
}

/* Day picker */
.rdp-vhidden {
  @apply sr-only;
}

.rdp-dropdown {
  @apply appearance-none border-none inset-0 w-full opacity-0 m-0 p-0 absolute z-[2];
}

.rdp-dropdown_year,
.rdp-dropdown_month {
  @apply relative;
}
